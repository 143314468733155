import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
  Nav,
  Collapse,
  NavItem,
  NavLink,
  NavbarToggler } from 'reactstrap';
  import { Link, withRouter } from 'react-router-dom';

  import { connect } from 'react-redux';
  import { bindActionCreators } from 'redux';
  import * as actions from '../../store/actions/actions';

  import ToggleFullscreen from '../Common/ToggleFullscreen';
  import HeaderRun from './Header.run';
  import MenuTopNavBar from '../../MenuTopNavBar.js';

  //scroll ref
  import Scrollchor from 'react-scrollchor';

  class HeaderHorizontal extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isOpen: false,
        path_page: '',
        logo_page: '',
        name_page: ''
      }
      switch (this.props.ruta.toLowerCase()) {
        case "/plataforma-norma-035":
        this.state.path_page = "/plataforma-norma-035";
        this.state.logo_page = "img/logo_plataforma_n_035.png";
        this.state.name_page = "plataforma_n_035";
        break;
        default:
        this.state.path_page = "/";
        this.state.logo_page = "img/logo_trascenti.png";
        this.state.name_page = "trascenti";
      }
    }



    componentDidMount() {
      HeaderRun();

      // Listen for routes changes in order to hide the sidebar on mobile
      this.props.history.listen(() => {
        this.setState({
          isOpen: false
        });
      });
    }

    toggle = () => {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }

    toggleOffsidebar = e => {
      e.preventDefault()
      this.props.actions.toggleSetting('offsidebarOpen');
    }

    render() {
      return (
        <header className="topnavbar-wrapper border-topnavbar">
          { /* START Top Navbar */ }
          <nav className="navbar topnavbar navbar-expand-lg navbar-light">
            { /* START navbar header */ }
            <div className="navbar-header">
              <a className="navbar-brand" href={this.state.path_page}>
                <div className="brand-logo">
                  <img className="img-fluid" src={this.state.logo_page} alt={this.state.name_page} style={{width: '90px', height: 'auto'}}/>
                </div>
                <div className="brand-logo-collapsed">
                  <img className="img-fluid" src="img/logo-single.png" alt="App Logo" />
                </div>
              </a>
              <NavbarToggler onClick={this.toggle} />
            </div>
            { /* END navbar header */ }
            { /* START Nav wrapper */ }
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav navbar className="mr-auto flex-column flex-lg-row">

                {
                  MenuTopNavBar.map((item, i) => {
                    if ( this.state.path_page.toLowerCase() == MenuTopNavBar[i].page ) {//validar acceso de opciones segun la pagina
                      if(MenuTopNavBar[i].item_type === 'submenu'){//validar tipo de elemento
                      return [
                        <UncontrolledDropdown nav inNavbar key={i}>
                          <DropdownToggle nav key={i}><span>{MenuTopNavBar[i].label}</span><em className="fa ml-2 fas fa-angle-down"/></DropdownToggle>
                          <DropdownMenu className="animated fadeIn">
                            {
                              MenuTopNavBar[i].submenu_items.map((subitem, j) =>
                              <div className="text-center" key={j}>
                                <a className="dropdown-item" href={subitem.link} key={j}>
                                  {/*<img className="" src={subitem.img} alt={subitem.label} style={{width: '100%', height: 'auto'}}/>*/}
                                  {subitem.label}
                                </a>
                              </div>
                            )}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      ]
                    }else if(MenuTopNavBar[i].item_type === 'navitem'){
                      return(
                        <NavItem key={i}>
                          <Scrollchor className="nav-link" animate={{ offset: -60 }} to={MenuTopNavBar[i].link} disableHistory={true}>
                            {MenuTopNavBar[i].label}<em className={MenuTopNavBar[i].icon}/>
                          </Scrollchor>
                        </NavItem>
                      )
                    }
                    return null; // unrecognized item
                  }
                })
              }

              { /* END Left navbar */ }
            </Nav>
            <Nav className="flex-row" navbar>
              { /* Fullscreen (only desktops) */ }
              <NavItem className="d-none d-md-block">
                <ToggleFullscreen className="nav-link"/>
              </NavItem>
            </Nav>
          </Collapse>
          { /* END Nav wrapper */ }
        </nav>
        { /* END Top Navbar */ }
      </header>
    );
  }

}

HeaderHorizontal.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HeaderHorizontal));
