const Menu = [
  {
    label: "MISIÓN Y VISIÓN",
    icon: '',
    item_type: "navitem",
    path: "mision_vision",
    link: "#mision_vision",
    page: "/"
  },
  {
    label: "VALORES",
    icon: '',
    item_type: "navitem",
    path: "valores",
    link: "#valores",
    page: "/"
  },
  {
    label: "CASOS DE ÉXITO",
    icon: '',
    item_type: "navitem",
    path: "casos_exito",
    link: "#casos_exito",
    page: "/"
  },
  {
    label: "SOLUCIONES",
    icon: '',
    item_type: "submenu",
    path: "soluciones",
    link: "#",
    page: "/",
    submenu_items: [
      {
        label: "Plataforma para el Bienestar Empresarial N-035",
        img: "img/logo_plataforma_bienestar_empresarial_n_035.png",
        link: "/plataforma-norma-035"
      }
    ]
  },
  {
    label: "ASOCIADOS",
    icon: '',
    item_type: "navitem",
    path: "asociados",
    link: "#asociados",
    page: "/"
  },
  {
    label: "NOSOTROS",
    icon: '',
    item_type: "navitem",
    path: "nosotros",
    link: "#nosotros",
    page: "/"
  },
  {
    label: "CONTÁCTANOS",
    icon: '',
    item_type: "navitem",
    path: "contactanos",
    link: "#contactanos",
    page: "/"
  },
  {
    label: "FUNCIONAMIENTO",
    item_type: "navitem",
    path: "funcionamiento",
    link: "#funcionamiento",
    page: "/plataforma-norma-035"
  },
  {
    label: "BENEFICIOS",
    icon: '',
    item_type: "navitem",
    path: "beneficios",
    link: "#beneficios",
    page: "/plataforma-norma-035"
  },
  {
    label: "INICIA UNA PRUEBA GRATUITA",
    icon: 'fa ml-2 fas fa-download',
    item_type: "navitem",
    path: "trial",
    link: "#trial",
    page: "/plataforma-norma-035",
  },
  {
    label: "DISTRIBUIDORES",
    icon: '',
    item_type: "navitem",
    path: "distribuidores",
    link: "#distribuidores",
    page: "/plataforma-norma-035"
  },
  {
    label: "CONTÁCTANOS",
    icon: '',
    item_type: "navitem",
    path: "contactanos",
    link: "#contactanos",
    page: "/plataforma-norma-035"
  }
];

export default Menu;
