import React, { Component } from 'react';
import {
  Row,
  Col
} from 'reactstrap';
import { Link } from 'react-router-dom';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trascenti: ( this.props.ruta.toLowerCase() == "/" ? true : false),
      plataforma_n_035: ( this.props.ruta.toLowerCase() == "/plataforma-norma-035" ? true : false),
    };
    switch (this.props.ruta.toLowerCase()) {
      case "/plataforma-norma-035":
      this.state.trascenti = false;
      this.state.plataforma_n_035 = true;
      this.state.first_color = "blue_light_n_035";
      this.state.second_color = "white";
      break;
      default:
      this.state.trascenti = true;
      this.state.plataforma_n_035 = false;
      this.state.first_color = "blue_tti";
      this.state.second_color = "orange_tti";
    }
  }

  render() {
    const year = new Date().getFullYear()
    return (
      <div className={"bg-"+(this.state.first_color)+" pt-5 pb-5"}>
        <div className="mb-10">
          <div className="row text-center" style={{margin:"auto"}}>

            <Col className="col-lg-4 col-md-12 p-10">
              <div className="pb-2">
                <img src="img/logo_trascenti_blanco.png" alt="logo_trascenti" style={{height:"30px",width:"auto"}}/>
              </div>
              <div className="pb-2" style={{display:(this.state.plataforma_n_035 ? '': 'none')}}>
                <img src="img/logo_plataforma_n_035_blanco.png" alt="logo_trascenti" style={{height:"30px",width:"auto"}}/>
              </div>
            </Col>

            <Col className="col-lg-4 col-md-12 text-white text-left" style={{display:(this.state.trascenti ? '': 'none')}}>
              <span style={{fontWeight: 'bold'}}>Soluciones</span>
              <ul>
                <li><Link className="text-white" to="/plataforma-norma-035">Plataforma para el Bienestar Empresarial</Link></li>
              </ul>
            </Col>
            <Col className="col-lg-4 col-md-12 text-white text-left">
              <span style={{fontWeight: 'bold'}}>Soporte</span>
              <ul>
                <li><a className="text-white" href="tel:4444756620">Hacer una llamada</a></li>
                <li><a className="text-white" href="mailto:service.desk@trascenti.com">Enviar un correo electrónico</a></li>
              </ul>
            </Col>
          </div>
        </div>

        <div className={"pt-10 text-white text-center border-top border-"+(this.state.second_color)}>
          <span>
            &copy; {year} - TRASCENTI SA DE CV TODOS LOS DERECHOS RESERVADOS
          </span>
        </div>
      </div>
    );
  }

}

export default Footer;
