import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';
import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';
import BaseHorizontal from './components/Layout/BaseHorizontal';
// import BaseHorizontal from './components/Layout/BaseHorizontal';

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props}/>;

const Index = lazy(() => import('./components/Trascenti/Index'));
const Plataforma_Norma_035 = lazy(() => import('./components/Plataforma-Norma-035/Index'));


// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
  /* See full project for reference */
];



const Routes = ({ location }) => {
  const currentKey = location.pathname.split('/')[1] || '/';
  const timeout = { enter: 500, exit: 500 };


  // Animations supported
  //      'rag-fadeIn'
  //      'rag-fadeInRight'
  //      'rag-fadeInLeft'

  const animationName = 'rag-fadeIn'

  if(listofPages.indexOf(location.pathname) > -1) {

    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader/>}>
          <Switch location={location}>
            {/* See full project for reference */}
          </Switch>
        </Suspense>
      </BasePage>
    )
  }
  else {

    return (
      // Layout component wrapper
      // Use <BaseHorizontal> to change layout
      <BaseHorizontal ruta={location.pathname}>
        <TransitionGroup>
          <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
            <div>
              <Suspense fallback={<PageLoader/>}>
                <Switch location={location}>
                  <Route path="/plataforma-norma-035" component={waitFor(Plataforma_Norma_035)}/>

                  <Route path="/" component={waitFor(Index)}/>


                  <Redirect to="/"/>
                </Switch>
              </Suspense>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </BaseHorizontal>
    )
  }
}

export default withRouter(Routes);
